import { Helmet } from "react-helmet";
import NoweInterpretacje from "./NoweInterpretacje";
import AlewersyAPI from "../services/AlewersyAPI";
import { useState, useEffect } from 'react';
import Paginator from "./Paginator";
import FakeA from "./fakeA";
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Parser } from 'react-tiny-bbcode';
import FileUpload from './FileUpload.js';
import NoweArtykuly from "./NoweArtykuly";

const alewersyAPI = AlewersyAPI.getInstance();

export default function ArticleEdit() {
    let navigate = useNavigate();
    let params = useParams();
    let [userData, setUserdata] = useState(null);

    let [article, setArticle] = useState({
        article_text: "",
        article_title: ""
    });

    function uploadDone(data) {
        //console.log('uploadDone');
        //console.log(data.data.path);

        article.article_text += `[img]${data.data.path}[/img]\n`;

        setArticle({
            ...article,
            article_text: article.article_text
        });
    }

    useEffect(() => {


        if (params.url) {
            (async () => {
                let art = await alewersyAPI.getArticle(params.url);

                setArticle(art[0]);

                // let ranking = await alewersyAPI.getRanking();
                // setRanking(ranking.sortedRanking);

                // let totalPages = Math.ceil(ranking.sortedRanking.length / perPage);
                // //console.log(`totalPages: ${totalPages}`);
                // setPageCount(totalPages);
            })();
        }

    }, []);

    async function saveArticle() {

        if (article.article_title.trim() === '' || article.article_text.trim() === '') {
            alert('tytuł oraz treść są wymagane!');
            return;
        }

        console.log('saving article...');
        console.log(article);

        let result = await alewersyAPI.saveArticle(article);
        if (result.insertId !== undefined) {
            // to był nowy artykuł

            setArticle({
                ...article,
                article_id: result.insertId
            });
            alert('Zapisano!');
        }

        if (result.status === 'success') {
            alert('Zapisano!');
        }
    }

    async function deleteArticle() {
        if (window.confirm('Czy na pewno usunąć?')) {
            let result = await alewersyAPI.deleteArticle(article);
            console.log(result);
            navigate('/panel');
        }
    }

    return <>
        <Helmet>
            <title>Ranking Użytkowników | tekst-piosenki.pl</title>
            <meta name="description" content="Top lista użytkowników publikujących treści w naszym serwisie. Dołącz do zabawy, publikuj śmieszne interpretacje i pnij się w rankingu największych trolli." />

        </Helmet>

        <section id="content">
            <div className="container">
                <div className="row">
                    <div className="span9">
                        <br />

                        Upload obrazków: <FileUpload uploadDone={uploadDone} />
                        Tytuł: <input type="text" style={{ width: '50%' }} value={article.article_title} onChange={(e) => { setArticle({ ...article, article_title: e.target.value }); }} />
                        <textarea style={{ width: "100%", height: '300px' }} value={article.article_text} onChange={(e) => { setArticle({ ...article, article_text: e.target.value }) }}></textarea>


                        <center><button onClick={(e) => { saveArticle(); }}>Zapisz artykuł</button>

                            {article.article_id !== undefined && <button onClick={(e) => { deleteArticle(); }}>Usuń artykuł</button>}

                            <button onClick={(e) => { navigate('/panel'); }}>Powrót</button></center>
                        <h3><b>{article.article_title}</b></h3>


                        <Parser bbcode={article.article_text} />

                    </div>
                    <div className="span3">
                        <aside className="right-sidebar">
                            <div className="widget">

                                <NoweArtykuly />
                            </div>
                        </aside>
                    </div>
                </div>
            </div>
        </section>
    </>
}

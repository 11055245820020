import { Helmet } from "react-helmet";
import NoweArtykuly from "./NoweArtykuly";
import AlewersyAPI from "../services/AlewersyAPI";
import { useState, useEffect } from 'react';
import Paginator from "./Paginator";
import FakeA from "./fakeA";
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Parser } from 'react-tiny-bbcode';

const alewersyAPI = AlewersyAPI.getInstance();

export default function Article() {
	let navigate = useNavigate();
	let params = useParams();
    let [shortText, setShortText] = useState("");
    const { url } = params;
    let [userData, setUserdata] = useState(null );

    let [article, setArticle] = useState({
        article_text: "",
        article_title: ""
    });

    useEffect(() => { 

        console.log(params);

        (async () => {
            let art = await alewersyAPI.getArticle(url);
            console.log(art);

            setArticle(art[0]);

            let shortText;
            let fullText = art[0].article_text;
            let fullimageMatch = new RegExp("\\[img](.+)\\[\\/img]");
            let fullimageMatchG = new RegExp("\\[img](.+)\\[\\/img]","g");

            shortText = fullText.replace(fullimageMatchG, '');
            shortText = shortText.replace( new RegExp('\\n', 'g'), ' ' );
            shortText = shortText.replace( new RegExp('\\[.+?\\]', 'g'), '' );
            shortText = shortText.trim();

            if (shortText.length>140) {
                shortText=shortText.substr(0, 140);

                shortText = shortText.trim();

                while(shortText.charAt(shortText.length-1) !== ' ') {
                    shortText = shortText.slice(0, -1);
                }

                shortText = shortText.trim();
                if (shortText.charAt(shortText.length-1) === ',')
                    shortText = shortText.slice(0, -1);
                shortText+='...';
            }

            setShortText(shortText);

            // let ranking = await alewersyAPI.getRanking();
            // setRanking(ranking.sortedRanking);

            // let totalPages = Math.ceil(ranking.sortedRanking.length / perPage);
            // //console.log(`totalPages: ${totalPages}`);
            // setPageCount(totalPages);
        })();

    }, [url]);
    

    return <>
        <Helmet>
            <title>{article.article_title} | tekst-piosenki.pl</title>
            <meta name="description" content={shortText} />

        </Helmet>

        <section id="content">
            <div className="container">
                <div className="row">
                    <div className="span9">
                        <br />
                        
                        <h3><b>{article.article_title}</b></h3>
                        					<Parser bbcode={article.article_text} />

                    </div>
                    <div className="span3">
                        <aside className="right-sidebar">
                            <div className="widget">
                                
                                <NoweArtykuly />
                            </div>
                        </aside>
                    </div>
                </div>
            </div>
        </section>
    </>
}

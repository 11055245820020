import { useState, useEffect } from 'react';
import FakeA from "./fakeA";
import AlewersyAPI from '../services/AlewersyAPI';
import { beautifyText } from "./Misc";
function getUniqueAnnotations(lastWeekS) {
    let lastWeekSunique = [];
    let lastWeekIdxes = [];

    lastWeekS.forEach((el, idx) => {
        if (lastWeekIdxes.indexOf(el.annotation_song_id) === -1) {
            lastWeekIdxes.push(el.annotation_song_id);
            lastWeekSunique.push(el);
        }
    });

    return lastWeekSunique;
}


const alewersyAPI = AlewersyAPI.getInstance();

export default function NoweArtykuly() {

    let [lastArticles, setLastArticles] = useState([]);

    useEffect(() => {

        (async () => {
            let articles = await alewersyAPI.getArticles();
            console.log(articles);
            setLastArticles(articles);

        })();
    }, []);



    return <>
        <h6 className="widgetheading">Nowe Artykuły</h6>

        {lastArticles.slice(0, 6).map((elem, idx) => {



            if (elem.article_url === undefined) {
                elem.article_url = '';
            }

            let thumb = null;

            let match = elem.article_text.match(/\[img\](.+)\[\/img\]/);
            // console.log(match);
            if (match) thumb = match[1];

            console.log(elem.article_text);

            let shortName = elem.article_title;
            if (shortName.length > 64) shortName = shortName.substr(0, 63) + '...';

            let s = {};
            if (idx <= 3) s = { marginBottom: '30px' };



            return (

                <center key={elem.annotation_id}>
                    <div className="mobile">
                        <div style={{ backgroundColor: '#fff2cc' }} className="team-box">
                            {/* <FakeA href={'/' + recentAnnotations.songs[elem.annotation_song_id].song_urlname.replace(new RegExp('\\/', 'g'), '%2F').replace(new RegExp('\\?', 'g'), '%3F').replace(new RegExp('\\#', 'g'), '%23')} style={{ backgroundColor: '#f0b400' }} className="thumbnail">

                                {recentAnnotations.songs[elem.annotation_song_id].song_youtube.id === undefined && <img src="/img/placeholder2.png" alt={recentAnnotations.songs[elem.annotation_song_id].song_search + " tekst"} />}
                                {recentAnnotations.songs[elem.annotation_song_id].song_youtube.id !== undefined && <img src={recentAnnotations.songs[elem.annotation_song_id].song_youtube.thumbnails.medium.url.replace(/https:\/\//g, '/api/ytproxy/')} alt={recentAnnotations.songs[elem.annotation_song_id].song_search + " tekst"} />}


                            </FakeA> */}

                            <FakeA href={'/artykul/' + elem.article_url.replace(new RegExp('\\/', 'g'), '%2F').replace(new RegExp('\\?', 'g'), '%3F').replace(new RegExp('\\#', 'g'), '%23')} style={{ backgroundColor: '#f0b400' }} className="thumbnail">

                                {!thumb && <img style={{ height: '66px', width: '100%', objectFit: 'cover' }} src="/img/placeholder.jpg" alt="" />}

                                {thumb && <img style={{ height: '66px', width: '100%', objectFit: 'cover' }} src={thumb} alt="" />}

                            </FakeA>

                            <div className="roles aligncenter">
                                <br />
                                <FakeA href={'/artykul/' + elem.article_url.replace(new RegExp('\\/', 'g'), '%2F').replace(new RegExp('\\?', 'g'), '%3F').replace(new RegExp('\\#', 'g'), '%23')}><p className="lead" style={{  fontSize: "1.2em" }}>


										<strong>{beautifyText(shortName)}  </strong></p>
									
									</FakeA>
                            </div>
                        </div>
                        <br />
                    </div>
                </center>





            )
        })}

    </>
}
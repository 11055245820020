import React, { useEffect } from 'react';

function Ad(props) {
    const { adtype } = props;

    
    useEffect(() => {
        try {
            window.adsbygoogle = window.adsbygoogle || []
            window.adsbygoogle.push({})
        } catch (err) {
            console.log(err);

        }

        console.log("useeffect ADD");
    })


    console.log("running ad function");

    if (props.adtype === '1') {
        return (<center key={adtype}>
            <ins className="adsbygoogle"
                style={{ display: 'inline-block', width: '728px', height: '90px' }}
                data-ad-client="ca-pub-2691069495400373"
                data-ad-slot="9408979719"></ins> </center>
        );
    }
    else if (props.adtype === '2') {
        return (<center>
            <ins className="adsbygoogle"
                style={{ display: 'inline-block', width: '728px', height: '90px' }}
                data-ad-client="ca-pub-2691069495400373"
                data-ad-slot="2843571367"></ins> </center>

        );
    } else if (props.adtype === '3') {
        return (<center>
            <ins className="adsbygoogle"
                style={{ display: 'inline-block', width: '728px', height: '90px' }}
                data-ad-client="ca-pub-2691069495400373"
                data-ad-slot="6431462586"></ins> </center>

        );
    } else if (props.adtype === '4') {
        return (<center>
            <ins className="adsbygoogle"
                style={{ display: 'inline-block', width: '336px', height: '280px' }}
                data-ad-client="ca-pub-2691069495400373"
                data-ad-slot="1879889771"></ins> </center>

        );
    } else return (<>no ad</>);
}

export default Ad;
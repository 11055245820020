import { Helmet } from "react-helmet";

export default function Prywatnosc() {
    return <>
        <Helmet>
            <title>Polityka Prywatności | tekst-piosenki.pl</title>
            <meta name="description" content="Polityka prywatności, informacje o przechowywaniu ciasteczek, kilka faktów prawnych i zbiór zasad korzystania z naszego serwisu." />

        </Helmet>

        <section style={{ backgroundColor: '#fff2cc' }} id="inner-headline">
            <div className="container">
                <div className="row">
                    <div className="span12">
                        <div className="inner-heading">
                            <ul className="breadcrumb">
                                <li><a href="/">Strona Główna</a> <i className="icon-angle-right" />  </li> 
                                  <li className="active">&nbsp;Regulamin</li>
                            </ul>
                            <h2>Polityka Prywatności</h2>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section id="content">



            <div className="container">
                <div className="row">
                    <div className="span4">
                        <aside className="left-sidebar">
                            <div className="widget">
                                <center>
                                    <img src="img/prywatnosc.png" />
                                </center>
                            </div>
                            <div className="widget">
                                <h6 className="widgetheading">WŁASNOŚĆ INTELEKTUALNA</h6>
                                Wszystkie treści zamieszczone na stronie internetowej pod adresem https://tekst-piosenki.pl/ korzystają z ochrony prawno autorskiej
                                i są własnością https://tekst-piosenki.pl/ Usługobiorca ponosi pełną odpowiedzialność za szkodę wyrządzoną Usługodawcy, będącą
                                następstwem użycia jakiejkolwiek zawartości strony https://tekst-piosenki.pl/, bez zgody Usługodawcy.
                                Jakiekolwiek wykorzystanie przez kogokolwiek, bez wyraźnej pisemnej zgody Usługodawcy, któregokolwiek z elementów
                                składających się na treść oraz zawartość strony https://tekst-piosenki.pl/ stanowi naruszenie prawa autorskiego przysługującego
                                Usługodawcy i skutkuje odpowiedzialnością cywilnoprawną oraz karną.
                                <br /><br />
                                <h6 className="widgetheading">POSTANOWIENIA KOŃCOWE</h6>
                                Umowy zawierane za pośrednictwem Strony zawierane są zgodnie z prawem polskim.
                                W przypadku niezgodności jakiejkolwiek części Regulaminu z obowiązującym prawem, w miejsce zakwestionowanego przepisu
                                Regulaminu zastosowanie mają właściwe przepisy prawa polskiego.
                                <br /><br />
                                <h6 className="widgetheading">TRYB POSTĘPOWANIA REKLAMACYJNEGO</h6>
                                Reklamacje związane ze świadczeniem Usług Elektronicznych przez Usługodawcę:
                                Reklamacje związane ze świadczeniem Usług Elektronicznych za pośrednictwem Strony Usługobiorca może składać za pośrednictwem
                                poczty elektronicznej na adres:
                                W powyższej wiadomości e-mail, należy podać jak najwięcej informacji i okoliczności dotyczących przedmiotu reklamacji,
                                w szczególności rodzaj i datę wystąpienia nieprawidłowości oraz dane kontaktowe. Podane informacje znacznie ułatwią
                                i przyspieszą rozpatrzenie reklamacji przez Usługodawcę.
                                Rozpatrzenie reklamacji przez Usługodawcę następuje niezwłocznie, nie później niż w terminie 14 dni.
                                Odpowiedź Usługodawcy w sprawie reklamacji jest wysyłana na adres e-mail Usługobiorcy podany w zgłoszeniu reklamacyjnym
                                lub w inny podany przez Usługobiorcę sposób.
                            </div>
                        </aside>
                    </div>
                    <div className="span8">
                        <br />
                        Strona
                        https://tekst-piosenki.pl/ działa na zasadach określonych w niniejszym Regulaminie.
                        <br />
                        - Regulamin określa rodzaje i zakres usług świadczonych drogą elektroniczną przez Stronę https://tekst-piosenki.pl/, zasady świadczenia
                        tych usług, warunki zawierania i rozwiązywania umów o świadczenie usług drogą elektroniczną, a także tryb postępowania
                        reklamacyjnego.
                        <br /> - Regulamin określa rodzaje i zakres usług świadczonych drogą elektroniczną przez Stronę https://tekst-piosenki.pl/, zasady
                        świadczenia tych usług, warunki zawierania i rozwiązywania umów o świadczenie usług drogą elektroniczną, a także
                        tryb postępowania reklamacyjnego.
                        <br /> - Każdy Usługobiorca z chwilą podjęcia czynności zmierzających do korzystania z Usług Elektronicznych Strony https://tekst-piosenki.pl/,
                        zobowiązany jest do przestrzegania postanowień niniejszego Regulaminu.
                        <br />
                        W sprawach nieuregulowanych w niniejszym Regulaminie mają zastosowanie przepisy
                        <br />
                        Ustawy o świadczeniu usług drogą elektroniczną z dnia 18 lipca 2002 r. (Dz. U. Nr 144, poz. 1204 ze zm.),
                        <br />
                        Ustawy o prawach konsumenta z dnia 30 maja 2014 r. (Dz. U. 2014 poz. 827),
                        <br />
                        Ustawy Kodeks cywilny z dnia 23 kwietnia 1964 r. (Dz. U. nr 16, poz. 93 ze zm.) oraz inne właściwe przepisy prawa
                        polskiego.
                        <br /><br />
                        Korzystając z witryny akceptujesz regulamin YouTube, kliknij tutaj aby dowiedzieć się więcej: <a href="https://www.youtube.com/t/terms" target="_blank" rel="nofollow"><font color="#3333ff">https://www.youtube.com/t/terms</font></a>
                        <br />
                        <h3>
                        </h3><h4><b>DEFINICJE</b></h4>
                        <br />
                        <b>FORMULARZ KONTAKTOWY</b> – formularz dostępny na Stronie https://tekst-piosenki.pl/ umożliwiający Usługobiorcy bezpośredni
                        kontakt z Usługodawcą.
                        <br /><br />
                        <b>REGULAMIN</b> – niniejszy regulamin Strony.
                        <br /><br />
                        <b>USŁUGODAWCA</b> – Admin wykonujący/a działalność na stronie https://tekst-piosenki.pl/
                        adres do doręczeń: https://tekst-piosenki.pl/
                        <br /><br />
                        <b>USŁUGOBIORCA</b> – osoba fizyczna, osoba prawna albo jednostka organizacyjna nieposiadająca osobowości prawnej, której
                        ustawa przyznaje zdolność prawną korzystająca z Usługi Elektronicznej.
                        <br /><br />
                        <b>USŁUGA ELEKTRONICZNA</b> – usługa świadczona drogą elektroniczną przez Usługodawcę na rzecz Usługobiorcy za pośrednictwem
                        Strony.
                        <br /><br />
                        Nasza strona korzysta z usług Google, czytaj więcej: <a href="https://policies.google.com/privacy" target="_blank" rel="nofollow"><font color="#3333ff">https://policies.google.com/privacy</font></a>
                        <br /><br />
                        <b>NEWSLETTER</b> – Usługa Elektroniczna pozwalająca Usługobiorcy na subskrybowanie i otrzymywanie na podany przez Usługobiorcę
                        adres e-mail bezpłatnych informacji pochodzących od Usługodawcy.
                        <br /><br />
                        <b>RODZAJ I ZAKRES USŁUG ELEKTRONICZNYCH</b>
                        <br /> Usługodawca umożliwia za pośrednictwem Strony korzystanie z Usług Elektronicznych takich jak:
                        <br /> korzystanie z Formularza Kontaktowego,
                        <br /> korzystanie z Newslettera,
                        <br /> Świadczenie Usług Elektronicznych na rzecz Usługobiorców odbywa się na warunkach określonych w Regulaminie.
                        <br /><br />
                        <b>WARUNKI ŚWIADCZENIA I ZAWIERANIA UMÓW O ŚWIADCZENIE USŁUG ELEKTRONICZNYCH</b>
                        <br /> Świadczenie Usług Elektronicznych określonych w rozdziale III pkt. 1 Regulaminu przez Usługodawcę jest nieodpłatne.
                        <br /> Okres na jaki umowa zostaje zawarta:
                        <br /> umowa o świadczenie Usługi Elektronicznej polegającej na umożliwieniu wysłania wiadomości za pośrednictwem Formularza
                        Kontaktowego zawierana jest na czas oznaczony i ulega rozwiązaniu z chwilą wysłania wiadomości albo zaprzestania
                        jej wysyłania przez Usługobiorcę.
                        <br /> umowa o świadczenie Usługi Elektronicznej polegającej na korzystaniu z Newslettera zawierana jest na czas nieoznaczony.
                        <br /> Wymagania techniczne niezbędne do współpracy z systemem teleinformatycznym, którym posługuje się Usługodawca:
                        <br /> komputer z dostępem do Internetu,
                        <br /> dostęp do poczty elektronicznej,
                        <br /> przeglądarka internetowa,
                        <br /> włączenie w przeglądarce internetowej Cookies oraz Javascript.
                        <br /> Usługobiorca zobowiązany jest do korzystania ze Strony w sposób zgodny z prawem i dobrymi obyczajami mając na uwadze
                        poszanowanie dóbr osobistych i praw własności intelektualnej osób trzecich.
                        <br /> Usługobiorca zobowiązany jest do wprowadzania danych zgodnych ze stanem faktycznym.
                        <br /> Usługobiorcę obowiązuje zakaz dostarczania treści o charakterze bezprawnym.
                        <br /><br />
                        Witryna korzysta z reklam Google Adsense, czytaj więcej: <a href="https://policies.google.com/technologies/ads" target="_blank" rel="nofollow"><font color="#3333ff">https://policies.google.com/technologies/ads</font></a>
                        <br /><br />
                        <b>WARUNKI ROZWIĄZYWANIA UMÓW O ŚWIADCZENIE USŁUG ELEKTRONICZNYCH</b>
                        <br /> Wypowiedzenie umowy o świadczenie Usługi Elektronicznej:
                        <br /> Wypowiedzeniu może ulec umowa o świadczenie Usługi Elektronicznej o charakterze ciągłym i bezterminowym (np. korzystanie
                        z Newslettera).
                        <br /> Usługobiorca może wypowiedzieć umowę ze skutkiem natychmiastowym i bez wskazywania przyczyn poprzez przesłanie stosownego
                        oświadczenia za pośrednictwem poczty elektronicznej na adres email.
                        <br /> Usługodawca może wypowiedzieć umowę o świadczenie Usługi Elektronicznej o charakterze ciągłym i bezterminowym w
                        przypadku, gdy Usługobiorca narusza Regulamin, w szczególności, gdy dostarcza treści o charakterze bezprawnym po
                        bezskutecznym wcześniejszym wezwaniu do zaprzestania naruszeń z wyznaczeniem odpowiedniego terminu. Umowa w takim
                        wypadku wygasa po upływie 7 dni od dnia złożenia oświadczenia woli o jej wypowiedzeniu (okres wypowiedzenia).
                        <br /> Wypowiedzenie prowadzi do ustania stosunku prawnego ze skutkiem na przyszłość.
                        <br /> Usługodawca i Usługobiorca mogą rozwiązać umowę o świadczenie Usługi Elektronicznej w każdym czasie w drodze porozumienia
                        stron.
                    </div>
                </div>
            </div>

        </section>
    </>
}
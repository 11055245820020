class AlewersyAPI {
    static myInstance = null;

    isLogged = false;

    refreshTimeout = null;

    static getInstance() {
        if (AlewersyAPI.myInstance === null) {
            AlewersyAPI.myInstance = new AlewersyAPI();
        }

        return this.myInstance;
    }

    async getRanking(str) {
        let res = await fetch(`/api/getRanking`);
        let json = await res.json();
        return json;
    }

    async identify(str) {
        let res = await fetch(`/api/identify/` + encodeURIComponent(str));
        let json = await res.json();
        return json;
    }

    async getArtists(offset, count) {
        let res = await fetch(`/api/get/artists/${offset}/${count}`);
        let json = await res.json();
        return json;
    }


    async getArticle(urlname) {
        let res = await fetch(`/api/getArticle/${urlname}`);
        let json = await res.json();
        return json;
    }

    async getArticles() {
        let res = await fetch(`/api/getArticles`);
        let json = await res.json();
        return json;
    }

    async getUser(username) {
        let res = await fetch(`/api/getUser/${username}`);
        let json = await res.json();
        return json;
    }

    async searchArtist(artist_name) {
        let res = await fetch(`/api/searchArtist/${artist_name}`);
        let json = await res.json();
        return json;
    }

    async getSongComments(song_id) {
        let res = await fetch(`/api/getSongComments/${song_id}`);
        let json = await res.json();
        return json;
    }

    async getSongTranslations(song_id) {
        let res = await fetch(`/api/getSongTranslations/${song_id}`);
        let json = await res.json();
        return json;
    }

    async getSongElement(song_id) {
        let res = await fetch(`/api/getSongElement/${song_id}`);
        let json = await res.json();
        return json;
    }

    async search(term) {
        let res = await fetch(`/api/search/${encodeURI(term)}`);
        let json = await res.json();
        return json;
    }

    async getRecentAnnotations() {
        let res = await fetch(`/api/recentAnnotations`);
        let json = await res.json();
        return json;
    }

    async getRecentArticles() {
        let res = await fetch(`/api/getRecentArticles`);
        let json = await res.json();
        return json;
    }

    async getRecentSongs() {
        let res = await fetch(`/api/recentSongs`);
        let json = await res.json();
        return json;
    }

    async getRandomSongs() {
        let res = await fetch(`/api/randomSongs`);
        let json = await res.json();
        return json;
    }

    async voteUpComment(comment_id, up = true, username = null) {
        let body = {
            comment_id,
            up,
            username
        };

        const response = await fetch(`/api/voteUpComment`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body) // body data type must match "Content-Type" header
        });

        let json = await response.json(); // parses JSON response into native JavaScript objects
        return json;
    }

    async deleteAccount(username) {
        let body = {
            username,
            userdata: JSON.parse(localStorage.userdata)
        };

        const response = await fetch(`/api/deleteAccount`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body) // body data type must match "Content-Type" header
        });

        let json = await response.json(); // parses JSON response into native JavaScript objects
        return json;
    }

    async saveArticle(article) {
        let body = {
            article
        };

        const response = await fetch(`/api/saveArticle`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body) // body data type must match "Content-Type" header
        });

        let json = await response.json(); // parses JSON response into native JavaScript objects
        return json;
    }

    async deleteArticle(article) {
        let body = {
            article
        };

        const response = await fetch(`/api/deleteArticle`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body) // body data type must match "Content-Type" header
        });

        let json = await response.json(); // parses JSON response into native JavaScript objects
        return json;
    }


    async deleteAnnotation(annotation_id) {
        let body = {
            annotation_id,
            userdata: JSON.parse(localStorage.userdata)
        };

        const response = await fetch(`/api/deleteAnnotation`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body) // body data type must match "Content-Type" header
        });

        let json = await response.json(); // parses JSON response into native JavaScript objects
        return json;
    }


    async registerUser(body) {

        const response = await fetch(`/api/registerUser`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body) // body data type must match "Content-Type" header
        });

        let json = await response.json(); // parses JSON response into native JavaScript objects
        return json;
    }


    async loginUser(body) {

        const response = await fetch(`/api/loginUser`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body) // body data type must match "Content-Type" header
        });

        let json = await response.json(); // parses JSON response into native JavaScript objects
        return json;
    }

    async voteUp(annotation_id, up = true, username = null) {
        let body = {
            annotation_id,
            up,
            username
        };

        const response = await fetch(`/api/voteUp`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body) // body data type must match "Content-Type" header
        });

        let json = await response.json(); // parses JSON response into native JavaScript objects
        return json;
    }

    async checkYoutubeURL(url) {
        const response = await fetch(`/api/checkURL`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                    url
                }) // body data type must match "Content-Type" header
        });

        let json = await response.json(); // parses JSON response into native JavaScript objects
        return json;
    }


    async saveSong(body) {
        const response = await fetch(`/api/saveSong`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body) // body data type must match "Content-Type" header
        });

        let json = await response.json(); // parses JSON response into native JavaScript objects
        return json;
    }

    async addNewElement(body) {
        const response = await fetch(`/api/addNewElement`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body) // body data type must match "Content-Type" header
        });

        let json = await response.json(); // parses JSON response into native JavaScript objects
        return json;
    }

    async addComment(body) {
        const response = await fetch(`/api/addComment`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body) // body data type must match "Content-Type" header
        });

        let json = await response.json(); // parses JSON response into native JavaScript objects
        return json;
    }

    async addTranslation(body) {
        const response = await fetch(`/api/addTranslation`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body) // body data type must match "Content-Type" header
        });

        let json = await response.json(); // parses JSON response into native JavaScript objects
        return json;
    }

    async addAnnotation(songId, annotationAuthor, annotationText, linesSelected, recaptcha) {
        let body = {
            songId,
            annotationAuthor,
            annotationText,
            linesSelected,
            'g-recaptcha-response': recaptcha
        };

        const response = await fetch(`/api/addAnnotation`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body) // body data type must match "Content-Type" header
        });

        let json = await response.json(); // parses JSON response into native JavaScript objects
        return json;
    }

    // async checkIfUserIsLogged () {
    //     console.log(new Date() + ": checkIfUserIsLogged");
    //     if (localStorage.token) { 
    //         console.log('we got token in memory. we should validate it');
    //         let validate = await AlewersyAPI.myInstance.validateSession(localStorage.token);

    //         // console.log(validate);

    //         if (validate.result === 'success') {
    //             console.log('TOKEN VALID');

    //             this.refreshTimeout = setInterval( async ()=> {
    //                 let res = await this.refreshToken();
    //                 if (res.result === 'newToken') {
    //                     console.log('using new token ');
    //                     localStorage.token = res.token;
    //                 }

    //                 if (res.error) {
    //                     clearInterval(this.refreshTimeout);
    //                 }

    //             }, 60*1000);

    //             return true;
    //         }
    //         return false;
    //     } 
    //     return false;
    // }

    // async refreshToken() {
    //     console.log(new Date()+': refreshing token...');
    //     let res = await fetch(`/api/refresh?token=${localStorage.token}`);
    //     let json = await res.json();
    //     return json;
    // }

    // async loginUser(username, password) {
    //     let res = await fetch(`/api/login?user=${username}&password=${password}`);
    //     let json = await res.json();

    //     if (json.token) {
    //         localStorage.token = json.token;
    //     }

    //     return json;
    // }

    // async validateSession(token) {
    //     let res = await fetch(`/api/validate?token=${token}`);
    //     let json = await res.json();
    //     return json;
    // }



    // async increaseView(body) {
    //     const response = await fetch(`/api/increase_view`, {
    //         method: 'POST', // *GET, POST, PUT, DELETE, etc.
    //         mode: 'cors', // no-cors, *cors, same-origin
    //         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    //         credentials: 'same-origin', // include, *same-origin, omit
    //         headers: {
    //           'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify(body) // body data type must match "Content-Type" header
    //     });

    //     let json = await response.json(); // parses JSON response into native JavaScript objects
    //     return json;
    // }

    // async giveRating(rate_body) {
    //     const response = await fetch(`/api/rate`, {
    //         method: 'POST', // *GET, POST, PUT, DELETE, etc.
    //         mode: 'cors', // no-cors, *cors, same-origin
    //         cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    //         credentials: 'same-origin', // include, *same-origin, omit
    //         headers: {
    //           'Content-Type': 'application/json',
    //         },
    //         body: JSON.stringify(rate_body) // body data type must match "Content-Type" header
    //     });

    //     let json = await response.json(); // parses JSON response into native JavaScript objects
    //     return json;
    // }
}

export default AlewersyAPI;
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ReCAPTCHA from "react-google-recaptcha";
import { Link, useParams, useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';
import AlewersyAPI from '../services/AlewersyAPI';
import '../css/Add.css';
import { checkText } from "./Misc";

const alewersyAPI = AlewersyAPI.getInstance();

export default function Dodaj() {
    let navigate = useNavigate();

    let [captcha, setCaptcha] = useState(null);
    let [artistValue, setArtistValue] = useState("ee");
    let [songTitle, setSongTitle] = useState("");
    let [songLyrics, setSongLyrics] = useState("");
    let [valToSearch, setValToSearch] = useState("");
    let [foundArtists, setFoundArtists] = useState([]);
    let [selectedArtist, setSelectedArtist] = useState(null);

    useEffect(() => {
        window.$('#da-slider').cslider();
        const script = document.createElement("script");
        script.src = "/js/custom.js";
        script.async = true;
        document.body.appendChild(script);
    }, []);

    function onCaptchaChange(value) {
        setCaptcha(value);
    }

    function selectArtist(artist_id) {
        foundArtists.forEach((elem) => {
            if (elem.artist_id === artist_id) {
                elem.selected = true;
                document.getElementById('artist_name').value = elem.artist_name;
                setSelectedArtist(elem);

            } else {
                elem.selected = false;
            }
        });

        setFoundArtists([...foundArtists]);
    }

    const onArtistInputChange = debounce(async (e) => {
        // console.log("OnInputChange",value);

        setArtistValue(e.target.value);

        (async () => {

            setValToSearch(e.target.value);
            //console.log("searching: " + e.target.value);

            if (e.target.value.trim() !== '') {
                let artists = await alewersyAPI.searchArtist(e.target.value.trim());
                setFoundArtists(artists);
                //console.log(artists.length);


            } else {
                setFoundArtists([]);
            }

            setSelectedArtist(null);

        })();

    }, 500);


    function firstToUpper(changed) {
        return changed.replace(/\b(\w)/gm, (a, b) => {
            return b.toUpperCase();
        });
    }

    async function addSong() {

        // if (checkText(valToSearch) === false) {
        //     alert('artist name can\'t include url or vulgarisms!');
        //     return;
        // }

        // if (checkText(songTitle) === false) {
        //     alert('song title can\'t include url or vulgarisms!');
        //     return;
        // }

        // if (checkText(songLyrics) === false) {
        //     alert('song lyrics can\'t include url or vulgarisms!');
        //     return;
        // }

        let artistName = firstToUpper(valToSearch);
        let songTitleUpper = firstToUpper(songTitle);
        if (selectedArtist) {
            console.log('existing artist');
        } else {
            console.log('new artist: ' + artistName);

            if (artistName.trim() === '') {
                alert('Nazwa wykonawcy jest wymagana!');
                return;
            }
        }

        if (songTitle.trim() === '') {
            alert('Tytuł piosenki jest wymagany!');
            return;
        }
        if (songLyrics.trim() === '') {
            alert('Tekst piosenki jest wymagany!');
            return;
        }

        console.log(`songTitleUpper: ${songTitleUpper}`);

        if (!captcha) {
            alert('Najpierw rozwiąż captchę!');
            return;
        }

        let body = {
            'g-recaptcha-response': captcha,
            artist: selectedArtist ? selectedArtist : artistName,
            lyrics: songLyrics,
            title: songTitle
        }


        let result = await alewersyAPI.saveSong(body);

        // console.log(result);
        if (result.result === 'error') {
            alert(result.error);
            return;
        }


        console.log(result.songURL);

        navigate('/' + result.songURL);

    }

    return <>
        <Helmet>
            <title>Dodaj Piosenkę | tekst-piosenki.pl</title>
            <meta name="description" content="Dodaj tekst piosenki! Wypełnij formularz na stronie - podaj nazwę artysty, tytuł utworu i tekst, a następnie ukończ wyzwanie captcha." />

        </Helmet>

        <section id="content">
            <div className="container">
                <div className="row">
                    <div className="span8">
                        <div className="post-image">
                            <center>
                                <br />
                                <h4 className="title"><strong>Dodaj Tekst Piosenki</strong></h4>
                            </center>
                        </div>
                        {/* <p style={{ margin: '5px' }}>Artysta:</p>
                        <input id="artist_name" type="text" style={{ width: '100%' }} />
                        <p style={{ margin: '5px' }}>Tytuł piosenki:</p>
                        <input type="text" defaultValue style={{ width: '100%' }} />
                        <p style={{ margin: '5px' }}>Tekst:</p>
                        <textarea style={{ width: '100%', minHeight: '300px' }} defaultValue={""} />
                        <center>
                            <br />
                            <a href="DODAWANIE PIOSENKI" style={{ backgroundColor: '#f0b400', border: '1px solid #f0b400', borderRadius: '3px' }} className="btn"><i className="icon-ok" />&nbsp; ZATWIERDŹ</a>
                        </center> */}

                        <p style={{ margin: '5px' }}>Artysta:</p>
                        <input id='artist_name' onChange={onArtistInputChange} type="text" style={{ width: '100%' }} />

                        {foundArtists.length > 0 && <p style={{ fontWeight: 'bold' }}>lub wybierz jednego z istniejących: </p>}
                        {foundArtists.map((artist) => {
                            return (<p
                                onClick={(e) => { selectArtist(artist.artist_id); }}
                                className={'clickable selectable' + (artist.selected ? ' selected_artist' : '')} key={artist.artist_id} style={{ display: 'inline-block', padding: '5px', margin: '5px', borderRadius: '3px', color: 'white', fontWeight: 'bold' }}>
                                {artist.artist_name}
                            </p>)

                        })}
                        <p style={{ margin: '5px' }}>Tytuł piosenki:</p>
                        <input value={songTitle} onChange={(e) => { setSongTitle(e.target.value) }} type="text" style={{ width: '100%' }} />

                        <p style={{ margin: '5px' }}>Tekst:</p>
                        <textarea value={songLyrics} onChange={(e) => { setSongLyrics(e.target.value) }} style={{ width: '100%', minHeight: '300px' }} />


                        <center> <ReCAPTCHA
                            style={{ margin: '5px' }}
                            sitekey="6LcGUv4dAAAAAG6RovDTkRpNL1MORZ6okrn27FgQ"
                            onChange={(value) => { onCaptchaChange(value); }}
                        /> </center>

                        {/* <a onClick={(e) => { addSong(); }} style={{ float: "right" }} className="btn btn-red">
                            <i className="icon-edit"></i>&nbsp; ADD SONG
                        </a> */}

                        <center>
                            <br />
                            <a onClick={(e) => { addSong(); }} style={{ backgroundColor: '#f0b400', border: '1px solid #f0b400', borderRadius: '3px' }} className="btn"><i className="icon-ok" />&nbsp; ZATWIERDŹ</a>
                        </center>

                    </div>
                    <div className="span4">
                        <center>
                            <br />
                            <img src="/img/interpretacje.png" />
                        </center>
                    </div>
                </div>
            </div>
        </section>



    </>
}
import { useState, useEffect } from 'react';
import { Helmet } from "react-helmet"
import AlewersyAPI from "../services/AlewersyAPI";
import {useNavigate} from "react-router-dom";
import FakeA from "./fakeA";
const alewersyAPI = AlewersyAPI.getInstance();

export default function Panel() {
    const navigate = useNavigate();
    let [gotAccess, setGotAccess] = useState(false);
    let [articles, setArticles] = useState([]);

    useEffect(()=>{
        if (localStorage.userdata!== undefined) {
            let parsed = JSON.parse(localStorage.userdata);
            if (parsed.username === 'admin') {
                setGotAccess(true);

                (async()=> {

                    let articles = await alewersyAPI.getArticles();
                    console.log(articles);
                    setArticles(articles);

                } )();
            }
        }

    }, []);

    return <>
        <Helmet>
            <title>Panel | tekst-piosenki.pl</title>
            <meta name="description" content="Chcesz zadać nam pytanie, podzielić się swoim pomysłem albo zgłosić błąd na stronie? Wyślij wiadomość na naszą skrzynką mailową, a my odpowiemy w ciągu 24 godzin." />
        </Helmet>



        <section id="content">
            <div className="container">
                <div className="row">
                    <div className="span8">

                    
                        {!gotAccess && <h1>brak dostępu</h1> }
                        {gotAccess && <>
                            <h1> Panel</h1>


                            <button onClick={(e)=> { navigate('/artykul_edit')} }>Nowy artykuł</button>

                            <table style={{margin: '5px', border: '1px solid #aaaaaa', width: '100%'}}>

                                {articles.map((elem, idx)=> {

                                    let d =  new Date(elem.article_timestamp * 1000);
                                    return <tr style={{borderBottom: '1px solid #aaaaaa'}} key={idx}>

                                        <td><FakeA href={'/artykul/'+elem.article_url} >{elem.article_title} </FakeA>    </td>
                                        
                                        <td>
                                            {d.toLocaleString()}
                                        </td>
                                        <td><button onClick={(e)=> {
                                            navigate('/artykul_edit/' + elem.article_url);
                                        }}>Edytuj</button></td>

                                    </tr>;
                                })}
                            </table>

                            </>}


                    </div>

                </div>
            </div>
        </section>
    </>

}